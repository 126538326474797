import React, { useState } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './CustomCss/Margins.css';
import './CustomCss/CustomStyle.css';
import OrionWideLogo from './Images/OrionLogo.svg';
//Icons for top Menu 
import NAULOGO from '../src/Images/Nau/NauLogo.png';
import InspectNAULogo from '../src/Images/inspect-nau-180.png';
// import NeptunesNest from '../src/Images/NN/Neptunes-Nest-VR-Logo.png';
import FleetManagement from '../src/Images/CommonImages/FleetManagementIcon.png';
import CrewManagement from '../src/Images/CommonImages/CrewIcon.png';
import QhsseManagement from '../src/Images/CommonImages/QhsseIcon.png';
import PlannedMaintenance from '../src/Images/CommonImages/PlannedMaintenance.png';
import VesselCertificate from '../src/Images/CommonImages/Vcms.png';
import Inventory from '../src/Images/CommonImages/invn.png';
import DmsIco from '../src/Images/CommonImages/DmsIcon.png';
import DocVault from '../src/Images/CommonImages/eCloudIcon.png';
import FleetApp from '../src/Images/CommonImages/NauApp.png';
import FleetAppICo from '../src/Images/CommonImages/FleetPerformanceApp_Icon.png';
import OrionBi from '../src/Images/CommonImages/OrionBiIcon.png';
import BlogIcon from '../src/Images/CommonImages/BlogIcon.png';
import OurPeople from '../src/Images/CommonImages/OurPeople.png';
import AboutUS from '../src/Images/CommonImages/AboutUs.png';
import TypeApproved from '../src/Images/CommonImages/TypeApproved.png';
import CompanyPolicy from '../src/Images/CommonImages/CompanyPolicy.png';
//scroll change effect 
function ColorSchemes() {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Navbar expanded={expanded} expand="lg" className='fixed-top small-navbar' bg="light" variant="light" collapseOnSelect>
        <Container fluid>
          <Navbar.Brand onClick={() => setExpanded(false)} as={Link} to='/' href='/'>
            <img src={OrionWideLogo} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" >
              <NavDropdown title="Our Concepts" id="navbarScrollingDropdown" className="nav-link">
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/NauServer">
                <img src={NAULOGO} alt="NAU 2.1" className="HeaderIcons"/> <span className="HeaderText">NAU 2.1</span>
                </NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/Neptunes-Nest" >
                <img src={NeptunesNest} alt="Neptunes Nest" className="HeaderIcons"/> <span className="HeaderText"> Neptunes Nest</span>

                </NavDropdown.Item> */}

                <NavDropdown title={<span>
                  <img src={FleetApp} alt="Fleet Performance App" className="HeaderIcons" style={{marginRight:"4px"}}/>
         <span className="HeaderText"> Orion Apps </span>
        </span>} id="navbarScrollingDropdown" className="nav-link OrionAppPlatform">
                               
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/Inspect-Nau-App" >
                <img src={InspectNAULogo} alt="Inspect Nau App" className="HeaderIcons"/> <span className="HeaderText"> Inspect Nau App</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/Fleet-Performance-Mobile-App" >
                <img src={FleetAppICo} alt="Fleet Performance App" className="HeaderIcons"/> <span className="HeaderText"> Fleet Performance App </span>
                </NavDropdown.Item>
              </NavDropdown>

              </NavDropdown>
              <NavDropdown title="Our Services" id="navbarScrollingDropdown" className="nav-link">
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/fleet-management" >
                <img src={FleetManagement} alt="Fleet Management" className="HeaderIcons"/> <span className="HeaderText">Fleet Management</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/Crew-Management-System">
                <img src={CrewManagement} alt="Crew Management" className="HeaderIcons"/> <span className="HeaderText"> Crew Management </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/QHSSE" >
                <img src={QhsseManagement} alt="QHSSE" className="HeaderIcons"/> <span className="HeaderText"> QHSSE </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/Planned-Maintenance-System" >
                <img src={PlannedMaintenance} alt="Planned Maintenance" className="HeaderIcons"/> <span className="HeaderText">  Planned Maintenance</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/Vessel-Certificate-Management-System" >
                <img src={VesselCertificate} alt="Certificate Management System" className="HeaderIcons"/> <span className="HeaderText"> Certificate Management System </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/supply-Chain" >
                <img src={Inventory} alt="Supply Chain Management" className="HeaderIcons"/> <span className="HeaderText"> Supply Chain Management</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="products/Electronic-Document-Management-System" >
                <img src={DmsIco} alt="Safety Management System" className="HeaderIcons"/> <span className="HeaderText"> Safety Management System</span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/products/ECloud" >
                <img src={DocVault} alt="E Cloud" className="HeaderIcons"/> <span className="HeaderText">E Cloud</span>
                </NavDropdown.Item>
                
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="Orion-Bi" >
                <img src={OrionBi} alt="Orion Business Intelligence" className="HeaderIcons"/> <span className="HeaderText"> Orion Business Intelligence </span>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Our Company" id="navbarScrollingDropdown" className="nav-link">
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/OurTeam" >
                <img src={OurPeople} alt="Our People" className="HeaderIcons"/> <span className="HeaderText"> Our People </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/about">
                <img src={AboutUS} alt="About Us" className="HeaderIcons"/><span className="HeaderText"> About Us </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/type-approved" >
                <img src={TypeApproved} alt="Type Approved" className="HeaderIcons"/><span className="HeaderText"> Type Approved </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="http://policy.orionmarineconcepts.com" >
                <img src={CompanyPolicy} alt="Company Policies" className="HeaderIcons"/><span className="HeaderText"> Company Policies </span>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/privacy-policy" >
                <img src={CompanyPolicy} alt="Privacy Policy" className="HeaderIcons"/><span className="HeaderText"> Privacy Policy </span>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Media" id="navbarScrollingDropdown" className="nav-link">
                <NavDropdown.Item onClick={() => setExpanded(false)} as={Link} className="nav-link" to="/news">
                <img src={BlogIcon} alt="Blog / News" className="HeaderIcons"/> <span className="HeaderText">Blog / News </span>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => setExpanded(false)} className="nav-link ButtonStl float-right" as={Link} to="/ScheduleDemo">
                Schedule a Demo
              </Nav.Link>
              <Nav.Link onClick={() => setExpanded(false)} className="nav-link float-right m-t-10" as={Link} to="/contact" >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default ColorSchemes;